<template>
  <div class="relative flex flex-col gap-1 pb-2 bg-white rounded-lg p-3">
    <!-- Post Header -->
    <div class="flex flex-col gap-2">
      <div class="text-gray-500 flex">
        <fw-person
          class="flex-1 cursor-default"
          :person="users[post.user_key]"
          size="xs"
          paddingless
          @click.native="$emit('open-comment')"
        >
          <template #secondline>
            <div class="text-gray-500 text-xs">
              {{ post.updated_date || post.created_date | humanDateTimePT }}
            </div>
          </template>
        </fw-person>
        <fw-menu-more v-if="canEdit || canDelete">
          <b-dropdown-item v-if="canEdit" @click="editComment">{{ $t('edit') }}</b-dropdown-item>
          <b-dropdown-item v-if="canDelete" @click="deleteComment">{{ $t('delete') }}</b-dropdown-item>
        </fw-menu-more>
      </div>
      <div class="fix-text-overflow" @click="$emit('open-comment')" v-html="post.message"></div>
    </div>
    <!-- Post Comments -->
    <div class="flex gap-3 justify-end items-center">
      <div v-if="false" class="text-gray-500 text-sm flex items-center gap-2 font-semibold">
        <fw-icon-message class="w-4 h-4" /> {{ post.comments.total }}
      </div>
      <div class="flex gap-0.5 items-center">
        <ButtonEmoji
          ref="love"
          emoji="love"
          size="xs"
          :number="post.reactions.love.total"
          @reaction="reactComment('love')"
        />
        <ButtonEmoji
          ref="applause"
          emoji="clap"
          size="xs"
          :number="post.reactions.applause.total"
          @reaction="reactComment('applause')"
        />
        <ButtonEmoji
          ref="like"
          emoji="thumbs_up"
          size="xs"
          :number="post.reactions.like.total"
          @reaction="reactComment('like')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonEmoji from '@/fw-modules/fw-core-vue/posts/components/buttons/ButtonEmoji'
import ServicePosts from '@/fw-modules/fw-core-vue/posts/services/ServicePosts'

export default {
  name: 'RecordPost',

  components: {
    ButtonEmoji
  },

  props: {
    post: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'post'
    },
    users: {
      type: Object,
      required: true
    },
    comments: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    me() {
      return this.$store.getters.getUser
    },
    canEdit() {
      return this.post.user_key == this.me.key
    },
    canDelete() {
      return this.post.user_key == this.me.key
    }
  },

  methods: {
    editComment() {
      this.$emit('edit', this.post)
    },
    showError(message = 'Ocorreu um erro. Tente de novo mais tarde ou contacte o suporte.') {
      this.$buefy.dialog.alert({
        message: message,
        type: 'is-danger',
        hasIcon: false,
        ariaRole: 'alertdialog',
        ariaModal: true
      })
    },
    async deleteComment() {
      this.$buefy.dialog.confirm({
        title: this.$t('delete_comment_title'),
        message: this.$t('delete_comment_message'),
        confirmText: this.$t('delete'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            let result = await ServicePosts.detelePost(this.post.key)
            console.log('deletePost', result)
            this.$emit('delete', this.post)
          } catch (e) {
            this.showError(this.$t('delete_comment_error'))
            console.error(e)
          }
        }
      })
    },
    async reactComment(reaction) {
      let reactions = ['love', 'applause', 'like', 'dislike']
      //cancel other reactions
      for (let key of reactions) {
        if (key != reaction && this.$refs[key]) {
          this.$refs[key].cancel()
        }
      }
      try {
        let result = await ServicePosts.reactPost(this.post.key, reaction)
        console.log('reactPost', result)
      } catch (e) {
        this.$refs[reaction].revert()
        this.showError(this.$t('reaction_error'))
        console.error(e)
      }
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "postType": {
      "post": "Publicação",
      "discussion": "Discussão",
      "comment": "Comentário"
    },
    "edit": "Editar",
    "delete": "Eliminar",
    "delete_comment_title": "Eliminar comentário",
    "delete_comment_message": "Tem a certeza que deseja eliminar este comentário?",
    "delete_comment_error": "Ocorreu um erro ao eliminar o comentário",
    "reaction_error": "Ocorreu um erro ao reagir ao comentário"
  },
  "en": {
    "postType": {
      "post": "Publicação",
      "discussion": "Discussão",
      "comment": "Comentário"
    },
    "edit": "Edit",
    "delete": "Delete",
    "delete_comment_title": "Delete comment",
    "delete_comment_message": "Are you sure you want to delete this comment?",
    "delete_comment_error": "An error occurred while deleting the comment",
    "reaction_error": "An error occurred while reacting to the comment"
  }
}
</i18n>

<style scoped>
.fix-text-overflow {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
}
</style>
