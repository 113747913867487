<template>
  <div class="w-full flex-1 flex flex-col min-h-0">
    <div class="flex-shrink-0 px-4 pt-4 pb-4 flex flex-col gap-4">
      <div class="flex gap-3 items-center">
        <fw-button v-if="showNavigation" type="xlight" size="xs" class="h-9" @click.native="$emit('go-back')">
          <fw-icon-chevron-left class="w-5 h-5" />
        </fw-button>
        <div class="flex-1">
          <fw-heading :counter="comments.length">{{ $t('comments') }}</fw-heading>
        </div>
        <fw-button v-if="showNavigation" size="sm" @click.native="$emit('close')">
          <fw-icon-close class="w-5 h-5" />
        </fw-button>
      </div>
      <div v-if="canComment && state != 'closed'" class="flex gap-3">
        <fw-button class="flex-1" type="dashed" @click.native="$emit('new-comment')">
          {{ $t('new_comment') }}
        </fw-button>
      </div>
      <fw-message v-if="state == 'closed'" type="info" class="text-sm">
        {{ $t('comments_closed') }}
      </fw-message>
    </div>
    <div class="flex flex-col gap-2 flex-1 min-h-0">
      <div v-if="loading" class="text-gray-300 text-center py-14 rounded-b-xl flex justify-center pt-4">
        <fw-icon-loading class="w-8 h-8" />
      </div>
      <div v-else-if="comments.length == 0" class="py-5 text-center text-gray-400 pt-4 text-sm">
        {{ $t('no_comments') }}
      </div>
      <div v-else class="flex-1 overflow-y-auto pt-2 px-4">
        <RecordCommentEditor
          v-for="(comment, p) in comments"
          :key="comment.key"
          minimal
          :post="comment"
          :users="users"
          :class="{ 'mb-1.5': p != comments.length - 1 }"
          :type="comment.type"
          @open-comment="$emit('open-comment', comment)"
          @edit="$emit('edit-comment', comment)"
          @delete="$emit('delete-comment', comment)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import RecordCommentEditor from '@/fw-modules/fw-core-vue/pages/components/records/RecordCommentEditor'
export default {
  components: {
    RecordCommentEditor
  },
  props: {
    version: {
      type: Object,
      default: null
    },
    comments: {
      type: Array,
      default: () => []
    },
    users: {
      type: Object,
      default: () => ({})
    },
    showNavigation: {
      type: Boolean,
      default: false
    },
    myrole: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      pagination: {
        page: 1,
        totalResults: 0,
        totalPages: 1,
        limit: 25
      }
    }
  },
  computed: {
    state() {
      return this.version ? this.version.state : null
    },
    canComment() {
      return (
        this.myrole === 'owner' || this.myrole === 'manager' || this.myrole === 'editor' || this.myrole === 'reviewer'
      )
    },
    collectionKey() {
      return this.version && this.version.collections.length > 0 ? this.version.collections[0].key : null
    },
    collections() {
      return this.version ? this.version.collections.map(el => el.key) || [] : []
    }
  }
}
</script>

<i18n>
{
  "en": {
    "comments": "Comments",
    "new_comment": "New comment",
    "no_comments": "No comments",
    "comments_closed": "New comments are blocked for this version"
  },
  "pt": {
    "comments": "Comentários",
    "new_comment": "Novo comentário",
    "no_comments": "Sem comentários",
    "comments_closed": "Novos comentários estão bloqueados nesta versão"
  }
}
</i18n>
